<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="error"
                  depressed
                  tile
                  @click="dialog = true"
                  v-if="$store.state.user_permissions.package_delete"
                >
                  Usuń
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="edit_package()"
                >
                  Zapisz zmiany
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-card>
          <v-card-title>Szczegóły pakietu</v-card-title>
          <v-card-text>

            <v-form
              ref="form" 
              v-model="valid"
              lazy-validation
            >

              <!-- <v-select
                v-model="user_data.category"
                :items="list"
                item-text="name"
                item-value="id"
                label="Kategoria pakietu"
                single-line
                :rules="$store.state.rules.not_null"
              ></v-select> -->

              <v-autocomplete
                v-model="user_data.category"
                :items="list"
                item-text="name"
                item-value="id"
                no-data-text="Brak wyników"
                label="Kategoria pakietu"
                :rules="$store.state.rules.not_null"
              ></v-autocomplete>

              <v-text-field
                v-model="user_data.price"
                label="Kwota (netto)"
                color="primary"
                suffix="zł"
              ></v-text-field>
              <v-textarea
                v-model="user_data.description"
                label="Opis"
                color="primary"
              ></v-textarea>

              <v-menu
                v-model="date_picker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="user_data.end_data"
                    label="Data wygaśnięcia pakietu"
                    prepend-icon="mdi-calendar"
                    color="primary"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="user_data.end_data"
                  @input="date_picker = false"
                  locale="pl-pl"
                  color="primary"
                ></v-date-picker>
              </v-menu>

            </v-form>

          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <confirm_dialog
      v-if="dialog"
      text="Czy na pewno chcesz usuąć pakiet?"
      @close="dialog = false;"
      @confirm="delete_package()"
    />
    
  </div>
</template>

<script>
export default {
  data: () => ({
    id: 0,
    valid: false,
    dialog: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista Klientów - pakiety',
        disabled: false,
        to: '/pakiety',
      },
      {
        text: 'Lista pakietów Klienta',
        disabled: false,
        to: '/pakiet',
      },
      {
        text: 'Szczegóły pakietu Klienta',
        disabled: false,
        to: '/pakiet-zobacz',
      },
      {
        text: 'Edycja pakietu Klienta',
        disabled: true,
        to: 'edytuj',
      },
    ],

    user_data: {
      price: '123',
      description: '',
      status: false,
      date_create: new Date().toISOString().substr(0, 10),
      date_invoice: '2021-01-01',
      number_invoice: '68/2/2021'
    },

    date_picker: false,
    list: [],
    
    search: ''
  }),
  methods: {
    delete_package(){
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/package/'+this.id, data: {}, method: 'DELETE' })
      .then(resp => {
        this.$router.push('/pakiet/'+this.user_data.assign_user);
        this.$store.commit("snackbar", {
          text: "Usunięto pakiet",
          color: "primary",
          btn_color: "white"
        });
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
    },
    edit_package(){
      if(this.$refs.form.validate()) {
        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("category", this.user_data.category);
        formData.append("description", this.user_data.description);
        formData.append("price", this.user_data.price);
        formData.append("end_data", this.user_data.end_data);
        formData.append("assign_user", this.user_data.assign_user);

        this.$axios({url: this.$store.state.api +'/package/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            this.$router.push('/pakiet-zobacz/'+this.id);
            this.$store.commit("snackbar", {
              text: "Zaktualizowano pakiet",
              color: "primary",
              btn_color: "white"
            });
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
    },
  },
  mounted(){
    
    this.id = this.$route.params.id;
    console.log(this.id);

    let loader = 2;

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/package/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        // Edytowanie linku
        this.breadcrumb[2].to = '/pakiet/'+resp.data.assign_user;
        this.breadcrumb[3].to = '/pakiet-zobacz/'+this.id;

        console.log(resp.data);
        this.user_data = resp.data;
        this.user_data.end_data = this.global_flipDate(this.user_data.end_data);
        this.$store.commit('loader_off');
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        loader--;
        if(!loader)this.$store.commit('loader_off');
      })

    this.$axios({url: this.$store.state.api +'/category_package', data: {}, method: 'GET' })
      .then(resp => {
        this.list = resp.data;
        console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        loader--;
        if(!loader)this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
.checkbox {
  margin-top: 0;
}
</style>